import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Default_Teacher, LessonInfo } from "../../../../../../assets/svg";
import { Accordion } from "../../../../../../components";
import { TermLessonAccordionType } from "../../../../../../components/Constants";
import useAuth from "../../../../../../hooks/useAuth";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import useTermAccordionActivity from "../../../../../../hooks/useTermAccordionActivity";
import { url } from "../../../../../../routes/utility";
import { addTimeZone, getTimeZone } from "../../../../../../components/Utility";
import { useEffect } from "react";

const LessonInfoPending = ({ data, selectedDate, termLessonAccordionActivities }) => {

    const strings = useLocalization();
    const { member } = useAuth();   
    const navigate = useNavigate();
    const { activities, addTermAccordionActivity } = useTermAccordionActivity(termLessonAccordionActivities);

    const selectedTermId = useSelector(state => state.termStore.termId) || false;
    const timeZone = getTimeZone(member.Timezone);

    // Bu useEffect, ders bilgisi akordiyonunun otomatik olarak açılmasını sağlar
    useEffect(() => {
        // Eğer aktiviteler varsa ve ders bilgisi akordiyonu henüz açılmamışsa, otomatik olarak açılır
        if(activities.length > 0 && !activities?.find(l => TermLessonAccordionType.LESSON_INFO === l.termLessonAccordionType)?.isOpened) { 
            // Ders bilgisini servise göndermek için addTermAccordionActivity fonksiyonu çağrılır
            addTermAccordionActivity(TermLessonAccordionType.LESSON_INFO, selectedTermId, selectedDate);
        }
    }, [activities]);

    return (
        <Accordion
            title={strings.speaking_lesson.daily_lesson.lesson_information}
            icon={LessonInfo}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            isOpen={true}
            openCloseCheckboxStatus={true}
        >
            <div className="flex flex-col items-center text-sm mt-4 pb-6">
                <p className="mb-5 sm:mb-3">{strings.speaking_lesson.daily_lesson.daily_lesson_information}</p>
                <div className="flex flex-col sm:flex-row sm:text-start sm:items-center">
                    {(data.teacher) && (
                        <img
                            className={classNames("rounded-full w-[100px] mx-auto sm:mr-3", {
                                "cursor-pointer": !(data?.teacher?.id === 1320 || data?.teacher?.id === 1500 || data?.teacher?.id === 1501)
                            })}
                            onClick={() => !(data?.teacher?.id === 1320 || data?.teacher?.id === 1500 || data?.teacher?.id === 1501) && navigate(url("teachers.detail", { teacherId: data?.teacher?.id }))}
                            src={data?.teacher?.profilePicture ?? Default_Teacher}
                            alt=""
                        />
                    )}
                    <div className="sm:mr-auto">
                        <p className="mt-3 sm:mt-0">{strings.speaking_lesson.daily_lesson.your_teacher} <span className="font-bold">{data.teacher && data?.teacher?.fullName.replace(" (Sanal Egitmen)", "")}</span></p>
                        <p className="mb-5 sm:mb-0 mt-2">{strings.speaking_lesson.daily_lesson.lesson_time} <span className="font-bold">{addTimeZone(data?.lessonTime,timeZone)}</span></p>
                    </div>
                </div>
                <p className="sm:mt-3">{strings.speaking_lesson.daily_lesson.lesson_content}</p>
            </div>
        </Accordion>
    )
}

export default LessonInfoPending;