import { useRef, useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { QuestionMark } from "../../../../../assets/svg";
import { ModalContent, ModalOverlay } from "../../../../../components/Modals";
import { useLocalization } from "../../../../../hooks/useLocalization";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { openModal, closeModal } from "../../../../../components/Utility";

const ChatContinueOrEndModal = ({ 
    type,
    showModal, 
    handleCloseModal, 
    handleContinue, 
    handleStartOver
}) => {
    const strings = useLocalization();

    const modalRef = useRef();
    const modalOverlayRef = useRef();
    
    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 640;

    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleClose = () => {
        if (!isMobile) {
            closeModal(modalOverlayRef, modalRef);
            setIsModalVisible(false);
        }
        handleCloseModal();
    };

    useEffect(() => {
        if (!isMobile) {
            if (showModal) {
                openModal(modalOverlayRef, modalRef);
                setIsModalVisible(true);
            } else {
                setIsModalVisible(false);
                if(modalOverlayRef.current && modalRef.current) {   
                    closeModal(modalOverlayRef, modalRef);
                }
            }
        }
    }, [showModal, isMobile]);

    const ModalContentBody = () => {
        return (
            <div className="flex flex-col items-center justify-center gap-6 py-4">
                {/* Question Mark Icon */}
                <img src={QuestionMark} alt="Question Mark" className="w-24 h-24" />

                {/* Title */}
                <p className="text-center mt-2.5">
                    {type === "start" ? 
                        strings.speaking_lesson.conversational_ai.continue_or_end_modal.continue_text :
                        strings.speaking_lesson.conversational_ai.continue_or_end_modal.end_text
                    }
                </p>

                {/* Buttons */}
                <div className="flex flex-col w-full gap-2 mt-2.5 items-center justify-center">
                    <button 
                        type="button"
                        onClick={handleContinue}
                        className="button primary-button max-w-[330px] w-full"
                    >
                        { type === "start" ? 
                            strings.speaking_lesson.quiz.continue : 
                            strings.speaking_lesson.conversational_ai.continue_or_end_modal.end
                        }
                    </button>

                    <button 
                        type="button"
                        onClick={handleStartOver}
                        className="button primary-button-outline max-w-[330px] w-full"
                    >
                        {type === "start" ? 
                            strings.speaking_lesson.speaking_lab.start_again :
                            strings.speaking_lesson.conversational_ai.continue_or_end_modal.cancel
                        }
                    </button>
                </div>
            </div>
        );
    };

    return (
        <>
            {isMobile ? (
                <BottomSheet
                    open={showModal}
                    onDismiss={handleClose}
                    className="mobile-view"
                    snapPoints={({ maxHeight }) => {
                        if (windowSize.height <= 667) {
                            return [Math.min(475, maxHeight * 0.9)];
                        }
                        return [Math.min(475, maxHeight * 0.7)];
                    }}
                    blocking={true}
                    defaultSnap={({ maxHeight }) => {
                        if (windowSize.height <= 667) {
                            return Math.min(475, maxHeight * 0.9);
                        }
                        return Math.min(475, maxHeight * 0.7);
                    }}
                >
                    <div className="p-4">
                        <ModalContentBody />
                    </div>
                </BottomSheet>
            ) : (
                <>
                    <ModalOverlay ref={modalOverlayRef} />
                    <ModalContent
                        ref={modalRef}
                        show={isModalVisible}
                        overlayRef={modalOverlayRef}
                        showCloseButton={true}
                        closeAction={handleClose}
                        className="sm:w-[500px] sm:h-auto"
                        aria-modal="true"
                    >
                        <div className="p-4">
                            <ModalContentBody />
                        </div>
                    </ModalContent>
                </>
            )}
        </>
    );
};

export default ChatContinueOrEndModal;