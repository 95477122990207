import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { Loading } from '../../../components';
import {EventLogs, MemberFamilyRole} from '../../../components/Constants';
import useAuth from '../../../hooks/useAuth';
import { url } from '../../../routes/utility';
import { GetFamilyMember } from '../../../services/LearnTogether/LearnTogetherService';
import { FamilyMember } from '../components';
import useAnalytics from "../../../hooks/useAnalytics";
import { getApplicationDirection } from '../../../components/Utility';

const Family = () => {

    const { member } = useAuth();
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const language = useSelector(state => state.localizationStore.langauge);
    const memberId = member.MemberId;

    const [loading, setLoading] = useState(true)
    const [familyMembers, setFamilyMembers] = useState([]);
    const [familyRole, setFamilyRole] = useState(-1);
    const [familyCode, setFamilyCode] = useState("");

    const getFamilyMember = async (memberId) => {
        setLoading(loading => true);

        const result = await GetFamilyMember(memberId);
        if (result.status === 200) {
            let resultContent = JSON.parse(result.content);
            setFamilyRole(familyRole => resultContent.familyRole)
            setFamilyMembers(familyMembers => resultContent.familyMembers)
            setFamilyCode(familyCode => resultContent.familyCode)
        }
        setLoading(loading => false);
    }

    const sendRequestToEndpoint = () => {
        getFamilyMember(memberId)
    }

    useEffect(() => {
        memberId && sendRequestToEndpoint()
    }, [memberId])

    useEffect(() => {
        familyRole === MemberFamilyRole.DEFAULT && navigate(url("learntogether.addfamilymember"), {
            state: { showSendRequestButton: true }
        });
    }, [familyRole])

    useEffect(() => {
        analytics.useAnalytics(EventLogs.REFERENCE_FAMILY_PAGE_VIEW);
    }, [])

    return (
        <div className={`main-content ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>

            {loading && <Loading />}

            {!loading && (
                <>
                    { }
                    {(familyRole === MemberFamilyRole.MANAGER || familyRole === MemberFamilyRole.MEMBER || familyRole === MemberFamilyRole.ATTEND_FAMILY_REQUEST) &&
                        <FamilyMember
                            familyCode={familyCode}
                            familyRole={familyRole}
                            familyMembers={familyMembers}
                            sendRequestToEndpoint={sendRequestToEndpoint}
                        />
                    }
                </>
            )}

            <div className="left-margin-bottom"></div>
        </div>
    )
}

export default Family