import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Loading } from "../../../components";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import { GetAIUseCaseSessionLogsByBookUnitId } from "../../../services/AIUseCaseHistory/AIUseCaseHistoryService";
import { formatDateFriendly, getLanguageIdByLanguageKey, getTimeZone } from "../../../components/Utility";
import { ModalFullScreenSlide } from "../../../components/Modals";
import IntelligentChat from "../components/IntelligentChat/IntelligentChat";

const ConversationalAIHistory = () => {
    const strings = useLocalization();

    const { member } = useAuth(); 
    const { bookUnitId, aiUseCaseId } = useLocation()?.state;
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);
    const timeZone = getTimeZone();

    const [historyData, setHistoryData] = useState({});
    const [loading, setLoading] = useState(true);
    const [selectedSession, setSelectedSession] = useState(null);

    useEffect(() => {
        GetAIUseCaseSessionLogsByBookUnitId({
            memberId: member?.MemberId,
            aiUseCaseId: aiUseCaseId,
            bookUnitId: bookUnitId,
            languageTitleType: getLanguageIdByLanguageKey(language)
        })
        .then((result) => {
            if(result.status === 200) {
                let data = JSON.parse(result.content);

                if (data?.aiUseCaseSessionItem?.length > 0) {
                    const dateTexts = {
                        today: strings.date.today,
                        yesterday: strings.date.yesterday,
                        daysAgo: strings.date.days_ago
                    };

                    data.aiUseCaseSessionItem = data.aiUseCaseSessionItem.map(item => {
                        const { time, displayDate } = formatDateFriendly(
                            item.aiUseCaseSessionCreateDate, 
                            timeZone,
                            dateTexts
                        );

                        return {
                            ...item,
                            time,
                            date: displayDate
                        };
                    });
                }

                setHistoryData(data);
            }
        })
        .catch()
        .finally(() => setLoading(false));
    }, [language, member?.MemberId, bookUnitId]);

    useEffect(() => {
        if (selectedSession) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        // Cleanup function
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [selectedSession]);

    const handleSessionClick = (session) => {
        setSelectedSession(session);
    };

    const handleCloseDetail = () => {
        setSelectedSession(null);
    };

    return (
        <>
            <div className="main-content">
                <div className="left-page">
                    {loading ? (
                        <Loading className="mt-5" />
                    ) : (
                        <motion.div 
                            className="h-full"
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ ease: "easeOut", duration: 0.3 }}
                        >
                            {/* Header */}
                            <div className="p-4 mt-1">
                                <div className="flex items-center justify-between">
                                    <h1 className="text-lg font-bold my-auto">{strings.speaking_lesson.conversational_ai.history}</h1>
                                    <button
                                        onClick={() => navigate(-1)}
                                        className="border border-onboarding-border hover:bg-onboarding-bg-select focus:bg-onboarding-bg-select focus:text-base gap-2 h-8 max-w-max px-3 flex flex-row items-center justify-center rounded-[10px]"
                                    >
                                        <span className="material-symbols-outlined">keyboard_return</span>
                                        {strings.ai_chatbot.back}
                                    </button>
                                </div>
                            </div>

                            {/* History List */}
                            <div className="p-4 space-y-3">
                                {historyData?.aiUseCaseSessionItem?.map((item, index) => (
                                    <div 
                                        key={index}
                                        className="bg-box rounded-xl p-3 cursor-pointer transition-all duration-200 hover:opacity-70"
                                        onClick={() => handleSessionClick(item)}
                                        role="button"
                                        tabIndex={0}
                                    >
                                        <div className="flex items-center gap-4">
                                            {/* Icon/Avatar */}
                                            <div className="w-10 h-10 bg-[#e5e5e5] rounded-full flex items-center justify-center flex-shrink-0">
                                                <span className="text-2xl">
                                                    {historyData?.bookUnitAiUseCaseContent?.emoji.length > 0 
                                                        ? historyData?.bookUnitAiUseCaseContent?.emoji 
                                                        : "💬"
                                                    }
                                                </span>
                                            </div>

                                            {/* Content */}
                                            <div className="flex-1 flex items-center justify-between">
                                                <div className="flex flex-col">
                                                    <h3 className="font-bold text-base">{item.date}</h3>
                                                    <div className="text-sm text-base-text/70">{historyData?.bookUnitAiUseCaseContent?.title}</div>
                                                </div>

                                                <div className="text-right">
                                                    <div className="font-bold">{item.time}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </motion.div>
                    )}

                    <div className="left-margin-bottom"></div>
                </div>
            </div>

            {/* Detay Modal */}
            <AnimatePresence mode="wait">
                {selectedSession && (
                    <ModalFullScreenSlide 
                        key="modal" 
                        handleCloseClick={handleCloseDetail}
                    >
                        <div className="h-full flex flex-col">
                            <div className="flex-1 overflow-y-auto">
                                <IntelligentChat 
                                    title={historyData?.bookUnitAiUseCaseContent?.title}
                                    emoji={historyData?.bookUnitAiUseCaseContent?.emoji}
                                    bookUnitId={bookUnitId}
                                    aiUseCaseSessionId={selectedSession.aiUseCaseSessionId}
                                    aiUseCaseId={aiUseCaseId}
                                />
                            </div>
                        </div>
                    </ModalFullScreenSlide>
                )}
            </AnimatePresence>
        </>
    );
};

export default ConversationalAIHistory;