import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, ModalOneButton, ModalOverlay, openModal } from "../../../components";
import { closeModal, getApplicationDirection } from "../../../components/Utility";
import useAnalytics from "../../../hooks/useAnalytics";
import useAuth from "../../../hooks/useAuth";
import { useLocalization } from "../../../hooks/useLocalization";
import { url } from "../../../routes/utility";
import { SendTicketService } from "../../../services/Support/SupportService";
import {ServiceErrorMessages} from "../../../components/Constants";

const SendTicket = () => {
  const strings = useLocalization();
  const analytics = useAnalytics();
  const { member, token } = useAuth();

  const language = useSelector(state => state.localizationStore.language);

  const ticketRef = useRef();
  const modalRef = useRef();
  const modalErrorRef = useRef();
  const modalOverlayRef = useRef();

  const [modalMessage, setModalMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  const submitHandle = async (e) => {
    e.preventDefault();

    let msg = ticketRef.current.value;
    if (msg.length > 10) {
      setLoading(true);
      const result = await SendTicketService(member.MemberId, msg, token);

      if (result.statusCode === 200) {
        setModalMessage(
          (modalMessage) => strings.support.send_request.modal_content
        );
        openModal(modalOverlayRef, modalRef);
        analytics.useAnalytics("Talep_Gonderildi");
        ticketRef.current.value = "";
      } else {
        if (result.message) {
          analytics.useAnalytics("Error_Occured", {
            error: `${result.message}`,
          });
        } else {
          analytics.useAnalytics("Error_Occured", {
            error: `An unexpected error occurred.`,
          });
        }

        if(result.message === ServiceErrorMessages.DAILY_TICKET_LIMIT_REACHED){
          setModalMessage(modalMessage => strings.support.send_request.request_limit_reached);
        } else {
          setModalMessage(modalMessage => strings.general_information_messages.an_error_occured);
        }

        openModal(modalOverlayRef, modalErrorRef);
      }
      setLoading(false);
    } else {
      setModalMessage(
        (modalMessage) => strings.support.send_request.modal_content_error
      );
      openModal(modalOverlayRef, modalErrorRef);
    }
   
  };

  return (
    <>
      <div className={`left-page ${getApplicationDirection(language) ? 'md:ml-7' : 'md:mr-7'}`}>
          <div className="mt-5 box-section px-[10px] xs:px-[10px] text-start text-sm">
            <p className="mt-0 font-bold text-center max-w-lg mx-auto px-5">
              {strings.support.send_request.title}
            </p>
          </div>

          <form id="form" onSubmit={submitHandle}>
            <textarea
              ref={ticketRef}
              cols=""
              rows="10"
              className="market-info-input mt-4 max-w-full placeholder:pt-1 pt-1 resize-none"
              placeholder={strings.support.send_request.placeholder}
            ></textarea>

            <div className="btn-outer-div">
              <Button
                  disabled={loading}
                  classnames={`button primary-button mt-0 ${loading ? "opacity-25 pointer-events-none" : ""}`}
                  type="submit"
                  text={ loading ? strings.general_information_messages.processing : strings.support.send_request.send }
              />
            </div>
          </form>

          <div className="left-margin-bottom"></div>
        </div>

        {/* Modal */}
        <ModalOverlay ref={modalOverlayRef} />
        <ModalOneButton
          ref={modalRef}
          overlayRef={modalOverlayRef}
          title={strings.modal.information_message}
          message={modalMessage}
          buttonText={strings.modal.okey}
          buttonClick={() => navigate(url("support.tickets"))}
        />
        <ModalOneButton
          ref={modalErrorRef}
          overlayRef={modalOverlayRef}
          title={strings.modal.information_message}
          message={modalMessage}
          buttonText={strings.modal.okey}
          buttonClick={() => closeModal(modalOverlayRef,modalErrorRef)}
        />
    </>
  );
};

export default SendTicket;
