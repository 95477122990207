import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Logo } from '../../assets/svg';
import {ModalOneButton, ModalTwoButtons, ModalOverlay, ModalContent, openModal, closeModal} from '../../components';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { MemberFamilyStatus, ServiceErrorMessages } from '../../components/Constants';
import { useLocalization } from '../../hooks/useLocalization';
import {DecideAttendFamilyRequest, GetFamilyMemberStatus} from '../../services/LearnTogether/LearnTogetherService';
import { url } from '../../routes/utility';
import useWindowSize from '../../hooks/useWindowSize';
import Lottie from 'lottie-react';
import errorAnimation from '../../assets/animation/error.json';
import checkmarkAnimation from '../../assets/animation/checkmark.json';

const JoinFamilyInvite = () => {

    const strings = useLocalization();
    const { base64 } = useParams();
    const decideAttendRequestModalRef = useRef();
    const decideAttendResultModalRef = useRef();
    const navigate = useNavigate();

    const modalOverlayRef = useRef();
    
    const [buttonLoading, setButtonLoading] = useState(false);
    const [decideAttendFamilyResultStatus, setDecideAttendFamilyResultStatus] = useState(MemberFamilyStatus);
    const [decideAttendFamilyResultText, setDecideAttendFamilyResultText] = useState("");

    const [decodedMemberId, setDecodedMemberId] = useState(null);
    const [decodedFamilyCode, setDecodedFamilyCode] = useState(null);
    const [decodedRequestMemberFullName, setDecodedRequestMemberFullName] = useState(null);

    const [requestSuccess, setRequestSuccess] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);

    const windowSize = useWindowSize();
    const isMobile = windowSize.width <= 768;

    const [memberFamilyStatus, setMemberFamilyStatus] = useState(null);

    const decideAttendFamilyRequest = async (memberId, familyCode, memberFamilyStatus) => {
        const model = {
            requestMemberId: memberId,
            memberFamilyCode: familyCode,
            memberFamilyStatus: memberFamilyStatus
        }

        const result = await DecideAttendFamilyRequest(model);
        closeModal(modalOverlayRef, decideAttendRequestModalRef);
        
        setRequestSuccess(result.status === 200);
        
        if (result.status === 200) {
            setDecideAttendFamilyResultText(memberFamilyStatus === MemberFamilyStatus.ACCEPTED
                ? strings.learn_together.accepted_attend_family_request_success_message.replace("#NAME#", decodedRequestMemberFullName)
                : strings.learn_together.rejected_attend_family_request_success_message.replace("#NAME#", decodedRequestMemberFullName));
        }
        else {
            if (result.content === ServiceErrorMessages.FAMILY_MANAGER_NOT_FOUND)
                setDecideAttendFamilyResultText(strings.learn_together.decide_attend_family_request_manager_not_found);
            else if(result.content === ServiceErrorMessages.ATTEND_FAMILY_REQUEST_NOT_FOUND)
                setDecideAttendFamilyResultText(strings.learn_together.decide_attend_family_request_not_found);
            else if(result.content === ServiceErrorMessages.ATTEND_FAMILY_QUOTA_FULL)
                setDecideAttendFamilyResultText(strings.learn_together.decide_attend_family_request_quota_full);
            else if (result.content === ServiceErrorMessages.ATTEND_FAMILY_REQUEST_ALREADY_DECIDED)
                setDecideAttendFamilyResultText(strings.learn_together.decide_attend_family_request_already_decided);
            else
                setDecideAttendFamilyResultText(strings.general_information_messages.an_error_occured);
        }
        
        setShowResultModal(true);
        openModal(modalOverlayRef, decideAttendResultModalRef);
    }

    useEffect(() => {
        if (base64) {
            try {
                const cleanBase64 = base64.replace('p=', '');
                const decodedData = atob(cleanBase64);
                const decodedAndUriData = unescape(decodeURIComponent(escape(decodedData)));
                
                const params = new URLSearchParams(decodedAndUriData);
                const memberId = params.get('memberId');
                const managerFamilyCode = params.get('managerFamilyCode');
                const requestMemberFullName = params.get('requestMemberFullName');
                
                setDecodedMemberId(memberId);
                setDecodedFamilyCode(managerFamilyCode);
                setDecodedRequestMemberFullName(requestMemberFullName);

                // Üyelik durumunu kontrol et
                checkMemberStatus(memberId, managerFamilyCode);
            } catch (error) {
                console.error('Base64 decode error:', error);
            }
        }
    }, [base64]);

    const checkMemberStatus = async (memberId, familyCode) => {
        const result = await GetFamilyMemberStatus(memberId, familyCode);
        if (result.status === 200) {
            setMemberFamilyStatus(result.content);
        }
    };

    const handleResultModalClose = () => {
        closeModal(modalOverlayRef, decideAttendResultModalRef);
        setShowResultModal(false);
        if (requestSuccess) {
            navigate(url("dashboard"));
        }
    };

    const SuccessContent = ({ message, onClose }) => {
        return (
            <div className="flex flex-col items-center gap-6 p-6">
                <div className="w-32 h-32">
                    <Lottie
                        animationData={checkmarkAnimation}
                        loop={false}
                    />
                </div>
                <p className="text-center" dangerouslySetInnerHTML={{ __html: message }} />
                <button 
                    className="button primary-button w-full"
                    onClick={onClose}
                >
                    {strings.modal.okey}
                </button>
            </div>
        );
    };

    const ErrorContent = ({ message, onClose }) => {
        return (
            <div className="flex flex-col items-center gap-6 p-6">
                <div className="w-32 h-32">
                    <Lottie
                        animationData={errorAnimation}
                        loop={false}
                    />
                </div>
                <p className="text-center" dangerouslySetInnerHTML={{ __html: message }} />
                <button 
                    className="button primary-button w-full"
                    onClick={onClose}
                >
                    {strings.modal.okey}
                </button>
            </div>
        );
    };

    const renderContent = () => {

        // Status 1 veya 2 ise sadece mesaj ve anasayfaya dön butonu göster
        if (memberFamilyStatus == MemberFamilyStatus.ACCEPTED || memberFamilyStatus == MemberFamilyStatus.REJECTED) {
            return (
                <>
                    <div className="text-center mx-2 max-w-lg flex flex-col gap-2">
                        <p className="text-lg" dangerouslySetInnerHTML={{ 
                            __html: memberFamilyStatus == MemberFamilyStatus.ACCEPTED
                                ? strings.learn_together.decide_attend_family_already_accepted.replace("#NAME#", decodedRequestMemberFullName)
                                : strings.learn_together.decide_attend_family_already_rejected.replace("#NAME#", decodedRequestMemberFullName)
                        }} />
                    </div>
                    <div className="btn-outer-div">
                        <button
                            className="button primary-button"
                            onClick={() => navigate(url("dashboard"))}
                        >
                            {strings.modal.okey}
                        </button>
                    </div>
                </>
            );
        }

        // Status 4 veya null ise normal onay/red butonlarını göster
        return (
            <>
                <div className="text-center mx-2 max-w-lg flex flex-col gap-2">
                    <p dangerouslySetInnerHTML={{ __html: strings.learn_together.decide_attend_family_title.replace("#NAME#", decodedRequestMemberFullName) }} />
                    <p>{strings.learn_together.decide_attend_family_content}</p>
                </div>
                <div className="btn-outer-div">
                    <button
                        disabled={buttonLoading}
                        className={classNames("button primary-button", {
                            "opacity-25 disabled:pointer-events-none": buttonLoading
                        })}
                        onClick={() => {
                            setDecideAttendFamilyResultStatus(MemberFamilyStatus.ACCEPTED);
                            openModal(modalOverlayRef, decideAttendRequestModalRef);
                        }}
                    >
                        {buttonLoading
                            ? strings.general_information_messages.processing
                            : strings.learn_together.decide_attend_family_approve
                        }
                    </button>
                    <button
                        disabled={buttonLoading}
                        className={classNames("button primary-button-outline button-mt", {
                            "opacity-25 disabled:pointer-events-none": buttonLoading
                        })}
                        onClick={() => {
                            setDecideAttendFamilyResultStatus(MemberFamilyStatus.REJECTED);
                            openModal(modalOverlayRef, decideAttendRequestModalRef);
                        }}
                    >
                        {buttonLoading
                            ? strings.general_information_messages.processing
                            : strings.learn_together.decide_attend_family_reject
                        }
                    </button>
                </div>
            </>
        );
    };

    return (
        <>
            <div className="h-full overflow-hidden flex flex-col justify-center items-center gap-10 md:gap-20">
                <div className="flex flex-col items-center z-20">
                    <img className="w-[90px] md:w-[120px]" src={Logo} alt="" />
                    <p className="font-bold text-2xl md:text-4xl mt-2 outline-title">{strings.brand_name}</p>
                </div>

                {renderContent()}
            </div>

            {/* --- Modals --- */}
            <ModalOverlay ref={modalOverlayRef} />
            
            <ModalTwoButtons
                ref={decideAttendRequestModalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.information_message}
                message={decideAttendFamilyResultStatus === MemberFamilyStatus.ACCEPTED
                    ? strings.learn_together.accept_attend_family_request_approve_message.replace("#NAME#", decodedRequestMemberFullName)
                    : strings.learn_together.reject_attend_family_request_approve_message.replace("#NAME#", decodedRequestMemberFullName)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    decideAttendFamilyResultStatus === MemberFamilyStatus.ACCEPTED
                        ? decideAttendFamilyRequest(decodedMemberId, decodedFamilyCode, MemberFamilyStatus.ACCEPTED)
                        : decideAttendFamilyRequest(decodedMemberId, decodedFamilyCode, MemberFamilyStatus.REJECTED)
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, decideAttendRequestModalRef);
                }}
            />

            {isMobile ? (
                <BottomSheet
                    open={showResultModal}
                    onDismiss={handleResultModalClose}
                    className="mobile-view"
                    snapPoints={({ maxHeight }) => {
                        if (windowSize.height <= 667) {
                            return [Math.min(475, maxHeight * 0.9)];
                        }
                        return [Math.min(475, maxHeight * 0.7)];
                    }}
                    blocking={true}
                    defaultSnap={({ maxHeight }) => {
                        if (windowSize.height <= 667) {
                            return Math.min(475, maxHeight * 0.9);
                        }
                        return Math.min(475, maxHeight * 0.7);
                    }}
                >
                    {requestSuccess ? (
                        <SuccessContent 
                            message={decideAttendFamilyResultText}
                            onClose={handleResultModalClose}
                        />
                    ) : (
                        <ErrorContent 
                            message={decideAttendFamilyResultText}
                            onClose={handleResultModalClose}
                        />
                    )}
                </BottomSheet>
            ) : (
                <ModalContent
                    ref={decideAttendResultModalRef}
                    show={showResultModal}
                    overlayRef={modalOverlayRef}
                    showCloseButton={false}
                    className="sm:w-[400px] sm:h-auto"
                    aria-modal="true"
                >
                    {requestSuccess ? (
                        <SuccessContent 
                            message={decideAttendFamilyResultText}
                            onClose={handleResultModalClose}
                        />
                    ) : (
                        <ErrorContent 
                            message={decideAttendFamilyResultText}
                            onClose={handleResultModalClose}
                        />
                    )}
                </ModalContent>
            )}
        </>
    )
}

export default JoinFamilyInvite