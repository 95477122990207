import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SpeakingLabPronunciation } from "../../../../../../assets/svg";
import { Accordion } from "../../../../../../components";
import {EventLogs, TermLessonAccordionType} from "../../../../../../components/Constants";
import { formatDateToCompactInteger } from "../../../../../../components/Utility";
import { useLocalization } from "../../../../../../hooks/useLocalization";
import useTermAccordionActivity from "../../../../../../hooks/useTermAccordionActivity";
import { url } from "../../../../../../routes/utility";
import useAnalytics from "../../../../../../hooks/useAnalytics";

const SpeakingLabContent = ({ bookUnitId, selectedDate, termLessonAccordionActivities }) => {

    const strings = useLocalization();
    const { activities, addTermAccordionActivity } = useTermAccordionActivity(
        termLessonAccordionActivities
    );
    const navigate = useNavigate();
    const analytics = useAnalytics();

    const selectedTermId = useSelector(state => state.termStore.termId) || false;

    const handleRouteSpeakingLab = () => {
        navigate(url("speakinglesson.speakinglab"), { state: { 
            bookUnitId: bookUnitId, 
            selectedDate: formatDateToCompactInteger(selectedDate), 
            selectedDateWithoutFormat: selectedDate 
        }});
        analytics.useAnalytics(EventLogs.GUNUN_DERSI_SPEAKING_LAB_CLICK);
    }

    return (
        <Accordion
            title={strings.speaking_lesson.speaking_lab.title}
            onClick={handleRouteSpeakingLab}
            disableOutsideClick={true}
            isScrollable={false}
            isDownArrowIcon={false}
            icon={SpeakingLabPronunciation}
            openCloseCheckboxStatus={
                activities
                    ?.find(l => TermLessonAccordionType.SPEAKING_LAB === l.termLessonAccordionType)?.isOpened
                || false
            }
            action={() => addTermAccordionActivity(TermLessonAccordionType.SPEAKING_LAB, selectedTermId, selectedDate)}
        />
    )
}

export default SpeakingLabContent;