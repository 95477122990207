import { LanguageTitleType } from '../../components/Constants';
import {fetchApi, fetchPythonApi} from '../index';

export const GetBookUnitExercise = async (model) => {

    const url = '/_services/book/v1/book_unit_exercise/get_book_unit_exercise/';

    const payload = {
        ...model,
        languageTitleType: LanguageTitleType.ARABIC
    };

    return await fetchApi(url, payload);
};

export const TtsWithSave = async (text, lang, textId) => {
    const url = "/ai/saved_tts";

    const payload = {
        text: text,
        lang: lang,
        textId: textId
    };

    return await fetchPythonApi(url, payload);
}

export const AddMemberSpeakingLab = async (model) => {
    const url = '/_services/member/v1/member_speaking_lab/add_member_speaking_lab/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const RestartMemberSpeakingLab = async (model) => {
    const url = '/_services/member/v1/member_speaking_lab/restart_member_speaking_lab/';

    const payload = {
        ...model
    };

    return await fetchApi(url, payload);
}

export const GetNearestBookUnitIdByMemberId = async (memberId) => {
    const url = '/_services/member/v1/term_schedule/get_nearest_book_unit_id_by_member_id/';

    const payload = {
        memberId: memberId
    };

    return await fetchApi(url, payload);
}