import classNames from 'classnames';
import React, {useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';
import {AddMember, Default_Teacher} from '../../../../assets/svg';
import {Button, closeModal, ModalOneButton, ModalOverlay, ModalTwoButtons, openModal} from '../../../../components';
import {MemberFamilyRole, MemberFamilyStatus, ServiceErrorMessages} from '../../../../components/Constants';
import {getApplicationDirection} from '../../../../components/Utility';
import {useLocalization} from '../../../../hooks/useLocalization';
import {url} from '../../../../routes/utility';
import {DecideAttendFamilyRequest,RemoveMemberFamily} from '../../../../services/LearnTogether/LearnTogetherService';

const FamilyMember = (props) => {

    const { familyMembers, familyRole, familyCode, sendRequestToEndpoint } = props;
    const strings = useLocalization();
    const navigate = useNavigate();
    const language = useSelector(state => state.localizationStore.language);

    const modalRef = useRef();
    const resultModalRef = useRef();
    const decideAttendRequestModalRef = useRef();
    const decideAttendResultModalRef = useRef();
    const modalOverlayRef = useRef();
    const FAMILY_MEMBER_COUNT = 5;

    const notSpecified = strings.speaking_lesson.speaking_lesson_user.it_is_not_specified;

    const [isHiddenRemoveButton, setIsHiddenRemoveButton] = useState(true);
    // Manager rolündeki kişi "Düzenle" butonuna bu state true'ye çekiliyor.
    const [isEditMembers, setIsEditMembers] = useState(false);
    const [memberToBeRemoved, setMemberToBeRemoved] = useState(null);
    const [removeMemberText, setRemoveMemberText] = useState("");
    const [memberToDecideAttendFamilyRequest, setMemberToDecideAttendFamilyRequest] = useState(null);
    const [decideAttendFamilyResultStatus, setDecideAttendFamilyResultStatus] = useState(MemberFamilyStatus);
    const [decideAttendFamilyResultText, setDecideAttendFamilyResultText] = useState("");


    //Düzenle butonuna basıldığında üye çıkarma butonunun css'ini kontrol etmek için kullanılan fonksiyon
    const handleClick = () => {
        !isEditMembers && setIsHiddenRemoveButton(isHiddenRemoveButton => !isHiddenRemoveButton);
        setTimeout(() => {
            isEditMembers && setIsHiddenRemoveButton(isHiddenRemoveButton => !isHiddenRemoveButton);
        }, 300);
    };

    const removeMemberFamily = async (memberId, familyCode) => {

        const model = {
            memberId: memberId,
            familyCode: familyCode
        }

        const result = await RemoveMemberFamily(model);
        closeModal(modalOverlayRef, modalRef);
        if (result.status === 200) {
            setRemoveMemberText(removeMemberText => strings.learn_together.remove_success_message.replace("#NAME#", memberToBeRemoved?.memberFullName))
        }
        else {
            setRemoveMemberText(removeMemberText => strings.menu.callbar.an_error_occured)
        }
        openModal(modalOverlayRef, resultModalRef);
    }

    const decideAttendFamilyRequest = async (memberId, familyCode, memberFamilyStatus) => {

        const model = {
            requestMemberId: memberId,
            memberFamilyCode: familyCode,
            memberFamilyStatus: memberFamilyStatus
        }
        
        const result = await DecideAttendFamilyRequest(model);
        closeModal(modalOverlayRef, decideAttendRequestModalRef);
        if (result.status === 200) {
            setDecideAttendFamilyResultText(memberFamilyStatus === MemberFamilyStatus.ACCEPTED 
                ? strings.learn_together.accepted_attend_family_request_success_message.replace("#NAME#", memberToDecideAttendFamilyRequest?.memberFullName)
                : strings.learn_together.rejected_attend_family_request_success_message.replace("#NAME#", memberToDecideAttendFamilyRequest?.memberFullName));
        }
        else {
            if (result.content === ServiceErrorMessages.FAMILY_MANAGER_NOT_FOUND)
                setDecideAttendFamilyResultText(strings.learn_together.decide_attend_family_request_manager_not_found);
            
            else if(result.content === ServiceErrorMessages.ATTEND_FAMILY_REQUEST_NOT_FOUND)
                setDecideAttendFamilyResultText(strings.learn_together.decide_attend_family_request_not_found);
            
            else if(result.content === ServiceErrorMessages.ATTEND_FAMILY_QUOTA_FULL)
                setDecideAttendFamilyResultText(strings.learn_together.decide_attend_family_request_quota_full);
            
            else if(result.content === ServiceErrorMessages.MEMBER_IS_ALREADY_INCLUDED_ANOTHER_FAMILY)
                setDecideAttendFamilyResultText(strings.learn_together.member_is_already_included_another_family_manager_error);
            
            else
                setDecideAttendFamilyResultText(strings.general_information_messages.an_error_occured);
        }
        openModal(modalOverlayRef, decideAttendResultModalRef);
    }

    return (
        <>
            <div className="mt-5 transparent-section overflow-x-hidden">
                <h5 className="text-secondary">{strings.learn_together.family_list}</h5>
                <div className='mt-3 flex justify-between'>
                    <p className='font-bold'>{strings.learn_together.members}</p>
                    {familyRole === MemberFamilyRole.MANAGER &&
                        <Button
                            classnames="button primary-button-outline max-w-max px-2 h-[25px] text-sm mt-0"
                            action={() => {
                                handleClick();
                                setIsEditMembers(isEditMembers => !isEditMembers);
                            }}
                            text={!isEditMembers ? strings.learn_together.edit : strings.modal.dont_approve}
                        />
                    }
                </div>
                <hr className='my-2' />
                {familyMembers.map((familyMember, index) => (
                    <div key={index}>
                        <div className='flex gap-2'>
                            <div className={classNames(`w-8 flex justify-center items-center transition-all duration-300 opacity-0 ${getApplicationDirection(language) ? 'translate-x-[32px]' : '-translate-x-[32px]'}`, {
                                "translate-x-0 !opacity-100": isEditMembers && familyMember?.memberFamilyRole !== MemberFamilyRole.MANAGER
                            })}>
                                {!isHiddenRemoveButton && familyMember?.memberFamilyRole !== MemberFamilyRole.ATTEND_FAMILY_REQUEST &&
                                    <button
                                        className='bg-red w-4 h-4 rounded-full text-white flex items-center justify-center'
                                        onClick={() => {
                                            setMemberToBeRemoved(memberToBeRemoved => familyMember)
                                            openModal(modalOverlayRef, modalRef)
                                        }}
                                    >-</button>
                                }
                            </div>
                            <button className={classNames(`flex items-center transition-all duration-300 w-full cursor-default ${getApplicationDirection(language) ? 'translate-x-[32px]' : '-translate-x-[32px]'}`, {
                                "translate-x-0": isEditMembers && familyMember?.memberFamilyRole !== MemberFamilyRole.MANAGER && familyMember?.memberFamilyRole !== MemberFamilyRole.ATTEND_FAMILY_REQUEST
                            })}>
                                <div className='flex gap-2 items-center flex-1'>
                                    <img className="rounded-full w-[32px]" src={Default_Teacher} alt="" />
                                    <div>
                                        <p className='text-[14px] font-bold text-start'>{familyMember.memberFullName || notSpecified}</p>
                                        <p className={classNames("text-[12px] text-secondary text-start -mt-1", {
                                            "font-bold": familyMember.memberFamilyRole === MemberFamilyRole.MANAGER
                                        })}>
                                            {(() => {
                                                switch (familyMember.memberFamilyRole) {
                                                    case MemberFamilyRole.MANAGER:
                                                        return strings.learn_together.manager;
                                                    
                                                    case MemberFamilyRole.ATTEND_FAMILY_REQUEST:
                                                        return strings.learn_together.attend_family_request;
                                                        
                                                    case MemberFamilyRole.MEMBER:
                                                    default:
                                                        return strings.learn_together.member;
                                                }
                                            })()}
                                        </p>
                                    </div>
                                </div>
                                
                                {familyMember.memberFamilyRole === MemberFamilyRole.ATTEND_FAMILY_REQUEST ? (
                                    <div className={`flex gap-1.5 ${getApplicationDirection(language) ? '-translate-x-[32px]' : 'translate-x-[32px]'}`}>
                                        <button 
                                            className="border border-green hover:bg-green text-green hover:text-white rounded-full w-6 h-6 flex items-center justify-center transition-colors"
                                            onClick={() => {
                                                setMemberToDecideAttendFamilyRequest(familyMember);
                                                setDecideAttendFamilyResultStatus(MemberFamilyStatus.ACCEPTED);
                                                openModal(modalOverlayRef, decideAttendRequestModalRef);
                                            }}
                                        >
                                            <span className="material-symbols-outlined text-[16px]">done</span>
                                        </button>
                                        <button 
                                            className="border border-red hover:bg-red text-red hover:text-white rounded-full w-6 h-6 flex items-center justify-center transition-colors"
                                            onClick={() => {
                                                setMemberToDecideAttendFamilyRequest(familyMember);
                                                setDecideAttendFamilyResultStatus(MemberFamilyStatus.REJECTED);
                                                openModal(modalOverlayRef, decideAttendRequestModalRef);
                                            }}
                                        >
                                            <span className="material-symbols-outlined text-[16px]">close</span>
                                        </button>
                                    </div>
                                ) : (
                                    familyRole === MemberFamilyRole.MANAGER && familyMember.memberFamilyRole !== MemberFamilyRole.MANAGER &&
                                    <span className={classNames("material-symbols-outlined transition-all duration-300 text-base-text text-opacity-0 text-[20px] translate-x-[32px]", {
                                        "opacity-0": isEditMembers && familyMember.memberFamilyRole !== MemberFamilyRole.MANAGER
                                    })}>
                                        arrow_forward_ios
                                    </span>
                                )}
                            </button>
                        </div>
                        {(index !== familyMembers.length - 1 || familyRole === MemberFamilyRole.MANAGER || FAMILY_MEMBER_COUNT > familyMembers.length) && <hr className='my-2' />}
                    </div>
                ))}
                {familyRole === MemberFamilyRole.MANAGER && FAMILY_MEMBER_COUNT > familyMembers.length &&
                    <button
                        className='flex gap-2 items-center ml-2'
                        onClick={() => navigate(url("learntogether.addfamilymember"), {
                            state: { showSendRequestButton: false }
                        })}
                    >
                        <img className="rounded-full w-[32px]" src={AddMember} alt="" />
                        <div>
                            <p className='text-[14px] font-bold'>{strings.learn_together.add_new_member}</p>
                            <p className='text-[12px] text-[#999999] text-start -mt-1'>{strings.learn_together.remaining_count.replace("#COUNT#", FAMILY_MEMBER_COUNT - familyMembers.length)}</p>
                        </div>
                    </button>
                }
            </div>

            {/* --- Modal --- */}
            <ModalOverlay ref={modalOverlayRef} />
            <ModalTwoButtons
                ref={modalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.information_message}
                message={strings.learn_together.remove_approve_message.replace("#NAME#", memberToBeRemoved?.memberFullName)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    removeMemberFamily(memberToBeRemoved.memberId, familyCode)
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, modalRef);
                }}
            />
            <ModalOneButton
                ref={resultModalRef}
                showCancelButton={false}
                title={strings.auth.information_messages.modal_title}
                message={removeMemberText}
                overlayRef={modalOverlayRef}
                buttonText={strings.modal.okey}
                buttonClick={() => {
                    closeModal(modalOverlayRef, resultModalRef);
                    setIsEditMembers(isEditMembers => !isEditMembers);
                    sendRequestToEndpoint();
                }}
            />

            {/* --- Decide Attend Family Request Modals --- */}
               <ModalTwoButtons
                ref={decideAttendRequestModalRef}
                overlayRef={modalOverlayRef}
                title={strings.modal.information_message}
                message={decideAttendFamilyResultStatus === MemberFamilyStatus.ACCEPTED 
                    ? strings.learn_together.accept_attend_family_request_approve_message.replace("#NAME#", memberToDecideAttendFamilyRequest?.memberFullName)
                    : strings.learn_together.reject_attend_family_request_approve_message.replace("#NAME#", memberToDecideAttendFamilyRequest?.memberFullName)}
                buttonText1={strings.modal.approve}
                buttonText2={strings.modal.dont_approve}
                buttonClick1={() => {
                    decideAttendFamilyResultStatus === MemberFamilyStatus.ACCEPTED 
                        ? decideAttendFamilyRequest(memberToDecideAttendFamilyRequest.memberId, familyCode, MemberFamilyStatus.ACCEPTED)
                        : decideAttendFamilyRequest(memberToDecideAttendFamilyRequest.memberId, familyCode, MemberFamilyStatus.REJECTED)
                }}
                buttonClick2={() => {
                    closeModal(modalOverlayRef, decideAttendRequestModalRef);
                }}
            />

            <ModalOneButton
                ref={decideAttendResultModalRef}
                showCancelButton={false}
                title={strings.auth.information_messages.modal_title}
                message={decideAttendFamilyResultText}
                overlayRef={modalOverlayRef}
                buttonText={strings.modal.okey}
                buttonClick={() => {
                    closeModal(modalOverlayRef, decideAttendResultModalRef);
                    sendRequestToEndpoint();
                }}
            />
        </>
    )
}

export default FamilyMember