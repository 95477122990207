import { useRef, useState } from "react";
import useWindowSize from "../../../../hooks/useWindowSize";
import { ModalContent, ModalOverlay } from "../../../../components";
import { BottomSheet } from "react-spring-bottom-sheet";
import { openModal } from "../../../../components/Utility";
import classNames from "classnames";
import { useLocalization } from "../../../../hooks/useLocalization";

const MoneyboxInfo = () => {

    const strings = useLocalization();

    const modalOverlayRef = useRef();
    const modalRef = useRef();
    const windowSize = useWindowSize();
    const isMobile = windowSize?.width < 640;

    const [showInfoModal, setShowInfoModal] = useState(false);

    const descriptions = [
        strings.learn_together.moneybox_transfer.info.description,
        strings.learn_together.moneybox_transfer.info.description_2,
        strings.learn_together.moneybox_transfer.info.description_3,
        strings.learn_together.moneybox_transfer.info.description_4,
    ];

    return (
        <>
            <div className="flex flex-col items-start w-full py-3 px-4 bg-primary/10 rounded-[10px] mt-5">
                <div className="flex items-center justify-between w-full">
                    <p className="font-bold">
                        {strings.learn_together.moneybox_transfer.info.title}
                    </p>
                    <button 
                        type="button"
                        className="text-[#309DFF] hover:opacity-80 transition-opacity mt-1"
                        onClick={() => {
                            setShowInfoModal(true);
                            if (!isMobile) {
                                openModal(modalOverlayRef, modalRef);
                            }
                        }}
                    >
                        <span className="material-symbols-outlined text-[28px]">info</span>
                    </button>
                </div>
            </div>
 
            {isMobile ? (
                <BottomSheet
                    open={showInfoModal}
                    onDismiss={() => setShowInfoModal(false)}
                    className="mobile-view"
                    snapPoints={({ maxHeight }) => [
                        Math.min(450, maxHeight * 0.85)
                    ]}
                    blocking={true}
                    defaultSnap={({ maxHeight }) => Math.min(450, maxHeight * 0.85)}
                >
                    <InfoContent isMobile={true} descriptions={descriptions} />
                </BottomSheet>
            ) : (
                <>
                    {/* Modal ve BottomSheet */}
                    <ModalOverlay ref={modalOverlayRef} />
                    <ModalContent
                        ref={modalRef}
                        show={showInfoModal}
                        overlayRef={modalOverlayRef}
                        showCloseButton={true}
                        closeAction={() => setShowInfoModal(false)}
                        className="sm:w-[500px] sm:h-auto"
                    >
                        <InfoContent isMobile={false} descriptions={descriptions} />
                    </ModalContent>
                </>
            )}
        </> 
    );
}

const InfoContent = ({ isMobile, descriptions }) => (
    <div className={classNames("flex flex-col", {
        "p-3": isMobile,
        "my-3": !isMobile
    })}>
        {!isMobile && (
            <span className="material-symbols-outlined text-[100px] text-primary w-full text-center mb-2">info</span>
        )}
        <div className="space-y-1.5">
            {isMobile && (
                <div className="flex items-center justify-center">
                    <span className="material-symbols-outlined text-[80px] text-primary">info</span>
                </div>
            )}
            <div className="space-y-2">
                {descriptions.map((description, index) => (
                    <div className="text-[14px]" key={index}>
                        <span className="font-bold">{index + 1})</span>{" "}
                        <span dangerouslySetInnerHTML={{ __html: description }}></span>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default MoneyboxInfo;